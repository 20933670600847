import { useCallback, useState, useEffect } from 'react';
import {
    Box,
    Button,
    Divider,
    Unstable_Grid2 as Grid,
    DialogActions,
    Dialog,
    DialogTitle,
    DialogContent,
    Select,
    MenuItem
} from '@mui/material';

import REST from '../utils/rest';
import { toastDismiss, toastError, toastLoading, toastSuccess } from '../utils/toasts';
import { useAuth } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { tr } from 'date-fns/locale';
export const RequestLanguageDialog = () => {

    const { t, i18n } = useTranslation()
    const { currentUser, requestLanguage, login, setRequestLanguage } = useAuth();
    const [languages, setLanguages] = useState()
    const [values, setValues] = useState({
        language: 1
    });
    const [blockSubmit, setBlockSubmit] = useState(false);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const sendLanguage = async (language, set_as_default, type) => await REST.POST(`ajax/set_language`, { language, set_as_default, type })
    const changeLanguage = async (type, l, set_as_default = true) => {

        await sendLanguage(l.language, set_as_default, type)//posílám jazyk do be

        //nabízím nastavit jako výchozí
        toast((ts) => (<span>
            {t(`general.language`)} {t(`general.changed`)}
        </span>));

        if (set_as_default) {
            currentUser.UserLangID = l?.LANGUAGE_ID;
            currentUser.UserLangISOCode = l?.LANGUAGE_ISO_CODE;
            login(currentUser)
        } else
            i18n.changeLanguage(l?.LANGUAGE_ISO_CODE)

        window.location.reload()
    }

    const getLanguages = useCallback(async () => {
        const [success, newdata] = await REST.GET(`languages`)
        if (!success) toastError()
        setLanguages(newdata?.Records)
    }, [])

    useEffect(() => {
        getLanguages()
    }, [])

    useEffect(() => {
        if (open) getLanguages()
    }, [open])

    let allLangString = 'Jazyk / Language / MoBa / Limba / Хэл / Ngon ngu / Язык ?'

    const handleSubmit = useCallback(
        async (event) => {
            setBlockSubmit(true);
            event.preventDefault();
            const loading = toastLoading();
            const [success, result] = await REST.POST(`ajax/set_language`, values);
            toastDismiss(loading);
            setBlockSubmit(false);

            if (!success)
                return toastError(t(result?.message));

            login(result);

            toastSuccess(t(`account.language_changed`));

            setRequestLanguage(false);

        }, [values]
    );

    return (
        <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
        >
            <Dialog open={requestLanguage}>
                <DialogTitle>{allLangString}</DialogTitle>
                <DialogContent>
                    <Box
                        container
                        spacing={3}
                    >
                        <Grid
                            xs={12}
                        >
                            <Select
                                fullWidth
                                name="language"
                                onChange={handleChange}
                                value={values.language}
                            >
                                {languages && languages.map((l, id) => (
                                    <MenuItem key={l.LANGUAGE_ID} value={l.LANGUAGE_ID}>{l.LANGUAGE_ISO_CODE} - {l.LANGUAGE_NAME}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Box>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => changeLanguage("null", values, true)}> {"✔"} </Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};